import { useEffect, useRef } from 'react'
import axios from 'axios'
import type { AxiosInstance } from 'axios'

import { useKeycloak } from '@react-keycloak/web'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { useConfig } from './ConfigContext'
import { isNumber } from 'lodash'

export const useAxios = (baseURL?: string) => {
  const { currentTenantId } = useConfig()
  const _baseURL: string = baseURL ? baseURL : BackofficeAPIConfig.BaseURL
  const axiosInstance = useRef<AxiosInstance>()
  const { keycloak, initialized } = useKeycloak()
  const kcToken = keycloak?.token ?? ''

  useEffect(() => {
    if (!isNumber(currentTenantId)) throw new Error('No tenant id specified')

    axiosInstance.current = axios.create({
      baseURL: _baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
        'x-noie-tenant-id': `${currentTenantId}`,
      },
    })

    return () => {
      axiosInstance.current = undefined
    }
  }, [_baseURL, currentTenantId, initialized, kcToken])

  return axiosInstance
}
