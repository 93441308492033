import * as React from 'react'
import '@patternfly/react-core/dist/styles/base.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppLayout } from '@app/layouts/AppLayout/AppLayout'
import { AppRoutes } from '@app/routes/routes'
import '@app/app.css'
import { useKeycloak } from '@react-keycloak/web'
import { Spinner } from '@patternfly/react-core'
import { ConfigProvider } from './utils/ConfigContext'

const App: React.FunctionComponent = () => {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return (
      <React.Fragment>
        <div className="pf-u-p-xl">
          <Spinner></Spinner>
        </div>
      </React.Fragment>
    )
  }

  return (
    <ConfigProvider>
      <Router>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </Router>
    </ConfigProvider>
  )
}

export default App
