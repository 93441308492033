import { LoginPage, Spinner } from '@patternfly/react-core'
import React from 'react'
import bg from '@app/assets/bgimages/about-well-ageing-cream-desktop.png'
import Tenant from './Tenant'
import { ITenant } from './ITenant'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const component: React.FunctionComponent = () => {
  const [tenants, setTenants] = React.useState([] as ITenant[])

  const title = 'Select tenant'
  const subTitle = 'Select the organization you want to login to:'

  const onTenantSelect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault()
    Tenant.setTenant(id)
    location.reload()
  }

  React.useEffect(() => {
    const abortController = new AbortController()

    const fetchAll = async () => {
      const tenants = (await Tenant.fetchTenants()) || []
      setTenants(tenants)
    }

    fetchAll().catch((error) => {
      alert(getErrorMessage(error))
    })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <LoginPage loginTitle={title} loginSubtitle={subTitle} backgroundImgSrc={bg}>
        {tenants.length > 0 && (
          <div>
            <ul>
              {tenants.map((p, i) => (
                <li key={i}>
                  <a href="#" onClick={(e) => onTenantSelect(e, p.id)}>
                    {p.handle}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {tenants.length === 0 && <Spinner size="md"></Spinner>}
      </LoginPage>
    </React.Fragment>
  )
}

const TenantSelect = React.memo(component)

export default TenantSelect
