import Tenant from '@lib/Tenant/Tenant'
import React from 'react'

export type GlobalConfig = {
  currentTenantId: number
  setCurrentTenantId: (tenantId: number) => void
}

const _currentTenantId = (typeof Tenant.getCurrentTenant()?.id === 'number' ? Tenant.getCurrentTenant()?.id : 0) as number

const ConfigContext = React.createContext<GlobalConfig>({
  currentTenantId: _currentTenantId,
  setCurrentTenantId: () => {},
})

export const ConfigProvider = ({ children }) => {
  const [currentTenantId, setCurrentTenantId] = React.useState<number>(_currentTenantId)

  return (
    <ConfigContext.Provider
      value={{
        currentTenantId,
        setCurrentTenantId,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => React.useContext(ConfigContext)
