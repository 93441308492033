import { ITenant } from '@lib/Tenant/ITenant'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import axios from 'axios'
import Cookies from 'universal-cookie'

export default class Tenant {
  static CookieName = 'BACKOFFICE_TENANT_ID'

  public constructor() {}

  public static async fetchTenants() {
    try {
      if (this.getTenants().length) {
        return this.getTenants()
      }

      const response = await axios.get(`${BackofficeAPIConfig.VersaURL}?action=list-tenants`)

      if (response?.data.tenants) {
        const tenants = response?.data.tenants as ITenant[]
        Tenant.setTenants(tenants)

        return tenants
      }

      return undefined
    } catch (error) {
      throw error
    }
  }

  public static setTenant(id) {
    const cookies = new Cookies(null, { path: '/' })
    cookies.set(this.CookieName, id)
  }

  public static getCurrentTenant() {
    const cookies = new Cookies(null, { path: '/' })
    const id = cookies.get(this.CookieName)

    return this.getTenants().find((p) => p.id === id)
  }

  public static setTenants(tenants: ITenant[]) {
    //sessionStorage.setItem('tenants', JSON.stringify(tenants))
    const cookies = new Cookies(null, { path: '/' })

    cookies.set('BACKOFFICE_TENANTS', JSON.stringify(tenants))
  }

  public static getTenants() {
    //const result = sessionStorage.getItem('tenants')
    const cookies = new Cookies(null, { path: '/' })
    const tenants = cookies.get('BACKOFFICE_TENANTS') as ITenant[]

    if (tenants) {
      return tenants
    } else {
      return []
    }
  }
}
