import React, { PropsWithChildren } from 'react'
import { useCookies } from 'react-cookie'
import TenantSelect from './TenantSelect'
import Tenant from './Tenant'

const component: React.FunctionComponent<PropsWithChildren> = (props: PropsWithChildren) => {
  const [cookies, setCookie] = useCookies()

  if (cookies[Tenant.CookieName]) {
    return <>{props.children}</>
  } else {
    return <TenantSelect></TenantSelect>
  }
}

const TenantProvider = React.memo(component)

export default TenantProvider
